import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

class TechStack extends React.Component {


  render() {
    return (
      <div className='pt-100'>
      <section className="binancestack technostack dev-tech-stack mb-0">
        <div className="container">
          <div className="row table-content">
            <div className="col-md-12 col-lg-6 text-left">
              <h5 className="heading-h2">White Label Crypto Exchange Software <span className="bluecolor d-lg-inline-block">Technology Stack</span></h5>
              <p className="pharagraph">We utilize the most advanced and effective tech stack for designing and developing Cryptocurrency exchange software.
              </p>
            </div>
            <div className="col-md-12 col-lg-6">
            <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/whitelabel/technologies-stock.png"
                alt="Technologies stock"
                className='logostck'
                width={573}
              />
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default TechStack             