import React ,{useEffect, useState} from 'react'
import { StaticImage } from 'gatsby-plugin-image'



const HowOur = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <div className='pt-100'>
    <section className="how-our mb-0">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h3 className="heading-h2">White Label Crypto Exchange Software <span className="heading-h3"><span className='bluecolor'>How It Works?</span></span> 
          </h3>
        </div>
        { isDesktop && (
          <StaticImage
          src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/workflow.png"
          alt="Workflow of white label crypto exchange software"
          width={1110}
        />
              )}
          { isMobile && (
          <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/white-label-crypto-exchange-mobile.webp" 
              alt="Workflow of white label crypto exchange software"
              width={392} />
              )}
      </div>
    </section>
    </div>
  )
}

export default HowOur