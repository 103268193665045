import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h4 className="heading-h2">Our White Label Cryptocurrency Exchange Software
              <span className='heading-h3'><span className="bluecolor">Development Approach</span></span> 
            </h4>
            
            <p className="text-center">
            Our experts will follow the outlined process to develop a top-tier Cryptocurrency Exchange platform. As a leading White Label Cryptocurrency Exchange Development Company, we ensure meticulous attention to detail and superior quality at every stage.
            </p>
          </div>
        </div>
        <div className="row table-content">
          <div className="col-lg-6 col-md-12  text-center mb-15">
              <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/requirement-gathering.png"
                alt="Requirement planning"
                width={291}
              />
          </div>
          <div className="col-lg-6 col-md-12 padl60">
            <h5>Requirement Gathering</h5>
            <p className="pharagraph">
            Gathering the requirements from our clients is the initial step that we follow for creating a cryptocurrency exchange. 
            </p>
            <br />
            <h5>Planning </h5>
            <p className="pharagraph">
            Our team will set the goals and objectives of the project. Besides this, we would set project deadlines and establish a budget for the project.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5>Designing</h5>
            <p className="pharagraph">
            Our skilled designers will obtain a skeletal design of the interface to be designed. Our unique and promising design of the software will help you to increase your user base.
            </p>
            <br />
            <h5>Development</h5>
            <p className="pharagraph">
            In the development phase, the architecture of our White Label Cryptocurrency Exchange is developed for all cryptocurrency audiences right from beginner to advanced levels. 
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1">
          <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/designing.png"
                alt="Designing and Development"
                width={370}
              />
          </div>
        </div>
        <div className="row table-content mb-0">
          <div className="col-lg-6 col-md-12  text-center mb-15">
          <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/testing-deployment.png"
                alt="Testing and Deployment"
                width={360}
              />
          </div>
          <div className="col-lg-6 col-md-12 padl60">
            <h5>Testing</h5>
            <p className="pharagraph">
            In this stage, our well-experienced testers will check the quality of our software solution by running all types of tests.
            </p>
            <br />
            <h5>Deployment</h5>
            <p className="pharagraph">
            After completing the development and testing stages, we would deploy the software on the server as per the request of the client.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach