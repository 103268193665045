import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class BannerSection extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">Best White Label Cryptocurrency Exchange Software</h1>
            <p className="sub-heading">Build a secure, scalable, and feature-rich crypto exchange with our Best White Label Cryptocurrency Exchange Software.</p>
            <p className="sub-small mb-3">Unlock advanced trading tools, features, and seamless platform integration.</p>
          </div>
          <div className="cta-banner justify-content-md-center mb-lg-4 mb-3">
              <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn mr-0">Book a calendly meeting</a>
          </div>
          <div className="quick text-center d-none d-md-block">
             <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/whitelabel/white-label-cryptocurrency-exchange.webp"
              alt="Whitelabel Crypto Exchange  Development Company Banner"
              width={980}
            />   
          </div>
          
        </div>
      </section>
    )
  }
}

export default BannerSection